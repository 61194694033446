import { FC } from 'react'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ListBulletIcon,
} from '@heroicons/react/24/solid'
import { Grid2 as Grid } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { navigate, routes } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'

interface Props {
  index: number
  courseLength: number
  onNext: () => void
  onBack: () => void

  isMobile?: boolean
  navigationToggle?: () => void
}

const CoursePlayerNavigation: FC<Props> = ({
  index,
  courseLength,
  onBack,
  onNext,
  isMobile = false,
  navigationToggle,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <div className="grid h-full min-w-full items-center border-t border-gray-300 bg-white p-1">
      {index + 1 !== courseLength ? (
        index !== 0 ? (
          <Grid container spacing={0} sx={{ height: '100%' }}>
            <Grid size={{ xs: 4 }}>
              <Button
                disableRipple
                className="h-full capitalize text-gray-900 hover:bg-gray-100"
                onClick={() => {
                  onBack()
                  trackEvent('Learner', 'Clicked Previous Lesson')
                }}
                variant="text"
                fullWidth
                startIcon={!isMobile && <ArrowLeftIcon className="h-4 w-4" />}
              >
                Previous Lesson
              </Button>
            </Grid>
            <Grid size={{ xs: 4 }} className="text-center">
              {isMobile && (
                <IconButton
                  className="rounded-full"
                  onClick={() => {
                    navigationToggle()
                    trackEvent('Learner', 'Clicked Navigation Toggle')
                  }}
                >
                  <ListBulletIcon className="w-8 text-indigo-600" />
                </IconButton>
              )}
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Button
                disableRipple
                className="h-full capitalize text-gray-900 hover:bg-gray-100"
                onClick={() => {
                  onNext()
                  trackEvent('Learner', 'Clicked Next Lesson')
                }}
                fullWidth
                variant="text"
                endIcon={!isMobile && <ArrowRightIcon className="h-4 w-4" />}
              >
                Next Lesson
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Button
            disableRipple
            className="h-full capitalize text-gray-900 hover:bg-gray-100"
            onClick={() => {
              onNext()
              trackEvent('Learner', 'Clicked Next Lesson')
            }}
            variant="text"
            endIcon={<ArrowRightIcon className="h-4 w-4" />}
            fullWidth
          >
            Next Lesson
          </Button>
        )
      ) : (
        <Button
          disableRipple
          className="h-full capitalize text-gray-900 hover:bg-gray-100"
          onClick={() => {
            navigate(routes.learnerCategories())
            trackEvent('Learner', 'Clicked Complete Course')
          }}
          variant="text"
          endIcon={<ArrowRightIcon className="h-4 w-4" />}
          fullWidth
        >
          Complete
        </Button>
      )}
    </div>
  )
}

export default CoursePlayerNavigation
