import { useState } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import CourseCell from 'src/components/Learner/Courses/CourseCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import { useAuth } from 'web/src/Providers'

const LearnerCoursePage = ({ id, activityId, taskId }) => {
  const [selectedTaskId, setSelectedTaskId] = useState(0)
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id

  return (
    <>
      <Metadata title="Learner Course" description="Learner Course page" />
      <FeatureToggle
        feature={FLAG_FEATURES.LEARNER_COURSES}
        InactiveComponent={<FeatureDenied />}
      >
        <PageHeader
          parentDataTestId="course-player-header"
          title="Course Player"
          backNavigation
        />
        <CourseCell
          clientId={clientId}
          id={id}
          selectedTaskId={selectedTaskId}
          setSelectedTask={setSelectedTaskId}
          activityId={activityId}
          taskId={taskId}
        />
      </FeatureToggle>
    </>
  )
}

export default LearnerCoursePage
