//mutation for create if not existing for task tracking
export const FIND_OR_CREATE_TASK_TRACKING = gql`
  mutation FindOrCreateLearnerTaskTrackingMutation(
    $input: CreateLearnerTaskTrackingInput!
  ) {
    findOrCreateLearnerTaskTracking(input: $input) {
      id
      progressData
    }
  }
`
